import {IHubConfig} from 'models/hubConfigs';
import {expiresInMillisecondsToMiliSeconds} from 'views/programming/hubManager/utils';

export const hubSaveParser = (model: Partial<IHubConfig>): Partial<IHubConfig> => {
  const postModel: Partial<IHubConfig> = {
    kidsOnly: false,
    published: false,
    ...model,
    expiresInMilliseconds: expiresInMillisecondsToMiliSeconds(model.expiresInMilliseconds),

    modifier: 'cms_tooling',
  };

  return postModel;
};
