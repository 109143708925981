import * as React from 'react';
import {
  Box,
  Button,
  Cluster,
  ContentBox,
  Divider,
  FormItem,
  Icon,
  ITableCol,
  Label,
  Popover,
  Table,
  Template,
  TextInput,
  TIcons,
  Toast,
} from '@pluto-tv/assemble';
import {TableActions} from 'components/tableActions';
import {CapstanSortForm} from './SortForm';
import {useState} from 'react';
import {cloneDeep, some} from 'lodash-es';
import {ICapstanFilters, ICapstanSort} from './models/capstan';
import {sortByOptions, sortDirectionOptions} from './sortOptions';

interface IFiltersPopover {
  add?: boolean;
  [key: number]: boolean;
}

const FILTERS_INIT: ICapstanFilters = {
  query: '',
  region: 'us',
  sortBy: [],
  offset: 0,
  limit: undefined,
};

interface ICapstanFiltersProps {
  capstanSyntax: string;
  isLoading: boolean;
  onChangeCapstanSyntax: (value: string) => void;
  setQueryFetchError: (string) => void;
  onSubmit: (filters: ICapstanFilters) => void;
}

const COUNT_LIMIT = 250;

export const FiltersSection = ({
  capstanSyntax,
  isLoading,
  onChangeCapstanSyntax,
  setQueryFetchError,
  onSubmit,
}: ICapstanFiltersProps): JSX.Element => {
  const [filtersParams, setFiltersParams] = useState(FILTERS_INIT);
  const [filtersPopoverOpen, setFiltersPopoverOpen] = useState<IFiltersPopover>({});

  const validSubmitState =
    !isLoading &&
    filtersParams.limit &&
    filtersParams.limit > 0 &&
    filtersParams.limit <= COUNT_LIMIT &&
    filtersParams.sortBy.length > 0;

  const handleClear = () => {
    onChangeCapstanSyntax('');
    setFiltersParams(FILTERS_INIT);
    setQueryFetchError('');
  };

  const handleQueryFetch = () => {
    if (validSubmitState) {
      setQueryFetchError('');
      const newFiltersParams = {...filtersParams, query: capstanSyntax};
      setFiltersParams(newFiltersParams);
      onSubmit(newFiltersParams);
    }

    // Placeholder to include the error if returned by the service, to be defined
    // setQueryFetchError('Syntax callback message goes here');
  };

  const handleRowActions = (icon: TIcons, index: number) => {
    if (icon === 'delete') {
      if (filtersParams.sortBy && filtersParams.sortBy.length <= 1) {
        Toast.error('This action cannot be completed. Must be at least 1 Sort option');
        return;
      }

      const cloned = cloneDeep(filtersParams.sortBy);
      if (!cloned) {
        return;
      }
      cloned.splice(index, 1);

      setFiltersParams({...filtersParams, sortBy: cloned});
    } else if (icon === 'edit') {
      setFiltersPopoverOpen({[index]: true});
    }
  };

  const handleSortingUpdate = (sortingRow: ICapstanSort, index = -1) => {
    const cloned = cloneDeep(filtersParams.sortBy || []);

    if (index >= 0) {
      if (sortingRow.criteria !== cloned[index].criteria && some(cloned, s => s.criteria === sortingRow.criteria)) {
        Toast.error('Sort By value already exists. Please try a different one');
        return;
      }
      cloned.splice(index, 1, sortingRow);
    } else {
      if (some(cloned, s => s.criteria === sortingRow.criteria)) {
        Toast.error('Sort By value already exists. Please try a different one');
        return;
      }
      cloned.push(sortingRow);
    }

    setFiltersParams({...filtersParams, sortBy: cloned});
    setFiltersPopoverOpen({});
  };

  const onDrop = (key, value, orig, dest) => {
    const sortingRows = [...filtersParams.sortBy];
    const row = sortingRows.splice(orig, 1)[0];
    sortingRows.splice(dest, 0, row);
    const newRowOrder = changeRowOrder(sortingRows);
    setFiltersParams({...filtersParams, sortBy: newRowOrder});
  };

  const changeRowOrder = (arr: ICapstanSort[]) => {
    return arr.map((item, index) => {
      return {...item, order: index + 1};
    });
  };

  return (
    <>
      <ContentBox>
        <Divider color='graphite' />
        <Box marginTop='medium' marginBottom='mediumNegative'>
          <Cluster justify='space-between'>
            <Box minWidth='25%'>
              <ContentBox title='Item Count*'>
                <FormItem required>
                  <>
                    <TextInput
                      type='number'
                      onChange={value => setFiltersParams({...filtersParams, limit: value})}
                      placeholder='Input Amount'
                      value={filtersParams.limit}
                      maxLength={3}
                    />
                    {(filtersParams.limit ?? 0) > COUNT_LIMIT && (
                      <Box marginTop='small'>
                        <Label state='error' size='medium'>
                          Max amount allowed is {COUNT_LIMIT}
                        </Label>
                      </Box>
                    )}
                  </>
                </FormItem>
              </ContentBox>
            </Box>

            <Box minWidth='70%'>
              <ContentBox title='Sorting*'>
                <Table
                  id='capstansorting'
                  fixedHeader={true}
                  draggable
                  dragKey='key'
                  dropKeys={['key']}
                  onDrop={onDrop}
                  emptyMsg='No Sort options provided'
                  rows={filtersParams.sortBy}
                  cols={[
                    {
                      label: 'Sort By',
                      transform: row => (
                        <>{sortByOptions.find(option => option.value === row.criteria)?.label || row.criteria}</>
                      ),
                    },
                    {
                      label: 'Sort Order',
                      transform: row => (
                        <>
                          {sortDirectionOptions.find(option => option.value === row.direction)?.label || row.direction}
                        </>
                      ),
                    },
                    {
                      label: 'Actions',
                      colWidth: '6.25rem',
                      transform: (row, _col, index) => (
                        <Cluster>
                          <TableActions
                            row={row}
                            icons={[]}
                            deleteOption={true}
                            onClick={(row, icon) => handleRowActions(icon, index)}
                          >
                            <Popover
                              appendToBody={true}
                              manualTrigger={true}
                              visible={filtersPopoverOpen[index]}
                              onClickOutside={() => setFiltersPopoverOpen({})}
                            >
                              <Template label='trigger'>
                                <Icon space='small' icon='edit' onClick={() => handleRowActions('edit', index)} />
                              </Template>
                              <Template label='popover'>
                                <CapstanSortForm
                                  onCancel={() => setFiltersPopoverOpen({})}
                                  visible={filtersPopoverOpen[index]}
                                  value={row}
                                  onSave={val => handleSortingUpdate(val, index)}
                                />
                              </Template>
                            </Popover>
                          </TableActions>
                        </Cluster>
                      ),
                    } as ITableCol<ICapstanSort>,
                  ]}
                ></Table>

                <Cluster justify='flex-end' align='center'>
                  <Popover
                    manualTrigger={true}
                    visible={filtersPopoverOpen.add}
                    onClickOutside={() => setFiltersPopoverOpen({})}
                  >
                    <Template label='trigger'>
                      <Button type='primary' onClick={() => setFiltersPopoverOpen({add: true})}>
                        + Add
                      </Button>
                    </Template>
                    <Template label='popover'>
                      <CapstanSortForm
                        isNew={true}
                        visible={filtersPopoverOpen.add}
                        value={{}}
                        onSave={val => handleSortingUpdate(val)}
                        onCancel={() => setFiltersPopoverOpen({})}
                      />
                    </Template>
                  </Popover>
                </Cluster>
              </ContentBox>
            </Box>
          </Cluster>
        </Box>
      </ContentBox>

      <ContentBox>
        <Divider color='graphite' />
        <Box marginTop='medium'>
          <Cluster justify='flex-end'>
            <Button ghost={true} onClick={handleClear}>
              Clear
            </Button>
            <Button type='primary' onClick={handleQueryFetch} state={validSubmitState ? '' : 'disabled'}>
              Submit
            </Button>
          </Cluster>
        </Box>
      </ContentBox>
    </>
  );
};
