import * as React from 'react';
import {
  Box,
  Button,
  Cluster,
  Icon,
  ITableCol,
  Popover,
  Table,
  TdLink,
  TdTextStatus,
  Template,
  TIcons,
  Toast,
  TSize,
} from '@pluto-tv/assemble';
import {TableActions} from 'components/tableActions';
import {ICarouselConfigSourceService} from 'models/carouselConfigs';
import {useAppPermissions} from 'app/permissions';
import SourceServiceForm from 'components/carouselList/components/sourceServiceform.tsx/SourceServiceForm';
import {convertToApiUrl, routeMappingWithCMSClassic} from 'components/carouselList/helpers/SourceServiceHelper';
import {useGetSourceServiceRows} from 'components/carouselList/hooks/useAssociatedCarouselById';
import SourceServiceRowState from 'views/programming/hubManager/carousels/edit/details/components/SourceServiceRowState';
import {
  sourceOptions,
  getServiceOptions,
  isAssociatedCarouselRequired,
} from 'components/carouselList/components/helpers/carouselServiceOptions';
import {SourceServiceModel} from 'views/programming/hubManager/carousels/edit';
import contentRoutes from 'routes/content.routes';
import programmingRoutes from 'routes/programming.routes';

interface ISourceServiceTableProps {
  sourceServiceModel: SourceServiceModel;
  setSourceServiceModel: (model: SourceServiceModel) => void;
  activeRegion: string;
}

interface ISourceServicePopover {
  add?: boolean;
  [key: number]: boolean;
}

const SourceServiceTable = ({
  sourceServiceModel,
  setSourceServiceModel,
  activeRegion,
}: ISourceServiceTableProps): React.ReactElement => {
  const {ableTo} = useAppPermissions();

  const canEdit = ableTo('CAROUSEL_EDIT');
  const canDelete = ableTo('CAROUSEL_DELETE');

  const {
    data: rows,
    isLoading: isLoadingRows,
    isError: isErrorRows,
  } = useGetSourceServiceRows(sourceServiceModel.apis || []);

  const [sourceServicePopoverOpen, setSourceServicePopoverOpen] = React.useState<ISourceServicePopover>({});

  const canAddSourceService = (sourceServiceModel.apis || []).length < 2;

  React.useEffect(() => {
    if (isErrorRows) {
      Toast.error('There was an error loading an associated carousel, please refresh the page.');
    }
  }, [isErrorRows]);

  // Hack to the popover element so it can be unmounted on close
  React.useLayoutEffect(() => {
    const element = document.getElementById('empty-source-service-form')?.parentElement;
    element?.style.setProperty('display', 'none');

    return () => {
      element?.style.removeProperty('display');
    };
  });

  const handleDelete = React.useCallback(
    async (index: number) => {
      const newApis = sourceServiceModel.apis!.filter((_api, idx) => idx !== index);
      setSourceServiceModel({apis: newApis});
    },
    [sourceServiceModel.apis, setSourceServiceModel],
  );

  const handleSourceServiceClick = React.useCallback(
    (icon: TIcons, index: number) => {
      if (icon === 'delete') {
        handleDelete(index);
      } else if (icon === 'edit') {
        setSourceServicePopoverOpen({[index]: true});
      }
    },
    [handleDelete],
  );

  const handleUpdateModel = React.useCallback(
    // if index is not provided, means an add operation
    async (values: Partial<ICarouselConfigSourceService>, index?: number) => {
      const {source, service, associatedCarousel} = values;
      if (!source || !service) return;

      const apiUrl = convertToApiUrl(values.source || '', service, associatedCarousel as {name: string; id: string});

      const associatedCarouselId = (associatedCarousel as {name: string; id: string})?.id.trim();
      const associatedCarouselName = (associatedCarousel as {name: string; id: string})?.name.trim();

      const sourceMapping = {
        mediacatalog: {mainCategoryId: associatedCarouselId, mainCategoryName: associatedCarouselName},
        recommender: {
          'similar-tvshows': {seriesId: associatedCarouselId, seriesName: associatedCarouselName},
          'similar-channels': {channelId: associatedCarouselId, channelName: associatedCarouselName},
          'similar-movies': {episodeId: associatedCarouselId, episodeName: associatedCarouselName},
        },
        'ml-carousels': {carouselId: associatedCarouselId, carouselName: associatedCarouselName},
        vod: {vodCategoryId: associatedCarouselId, vodCategoryName: associatedCarouselName},
      };

      const apiParams = source === 'recommender' ? sourceMapping[source][service] || {} : sourceMapping[source] || {};

      const newModel: Partial<any> = {
        apiService: index === 0 ? `service-${values.source}` : sourceServiceModel.apiService,
        apiUrl: index === 0 ? apiUrl : sourceServiceModel.apiUrl,
        apis:
          index !== undefined
            ? sourceServiceModel.apis!.map((api, idx) => {
                if (index === idx) {
                  return {
                    ...sourceServiceModel.apis![idx],
                    apiUrl: apiUrl,
                    apiService: `${values.source}#${values.service}`,
                    apiParams: apiParams,
                    policy: (values.policy || 'append').toLowerCase(),
                    position: values.position,
                  };
                }
                return api;
              })
            : [
                ...sourceServiceModel.apis!,
                {
                  apiUrl: apiUrl,
                  apiService: `${values.source}#${values.service}`,
                  apiParams: apiParams,
                  policy: (values.policy || 'append').toLowerCase(),
                  position: values.position,
                },
              ],
      };

      setSourceServiceModel(newModel);
      setSourceServicePopoverOpen({});
    },
    [sourceServiceModel, setSourceServiceModel],
  );

  const getAssociatedCarouselUrl = React.useCallback((row: ICarouselConfigSourceService): string | undefined => {
    const associatedCarouselPathMapppings = {
      vod: {
        categories: programmingRoutes.paths.vodCollectionEditProgramPage,
      },
      recommender: {
        ['similar-channels']: programmingRoutes.paths.channelEditDetailsPage,
        ['similar-movies']: contentRoutes.paths.episodeEditDetailsPage,
        ['similar-tvshows']: contentRoutes.paths.seriesEditDetailsPage,
      },
      mediacatalog: {
        ['main-categories']: `${routeMappingWithCMSClassic()}/main-categories/:id`,
      },
      'ml-carousels': {
        ['automated-carousels']: '',
      },
    };

    if (
      isAssociatedCarouselRequired(row.source, row.service) &&
      row.associatedCarousel !== 'error' &&
      row.associatedCarousel?.id
    ) {
      return associatedCarouselPathMapppings[row.source][row.service]?.replace(':id', row.associatedCarousel.id);
    }
  }, []);

  const columns = React.useMemo(
    () => [
      {
        label: '',
        colWidth: '0.625rem' as TSize,
        zeroRightPadding: true,
        transform: row => (
          <SourceServiceRowState
            row={row}
            allowedSources={sourceOptions.map(op => op.value)}
            allowedServices={getServiceOptions(row.source).map(op => op.value)}
          />
        ),
      },
      {
        label: 'Source',
        transform: row => <TdTextStatus state='highlight' title={row.source} />,
      },
      {
        label: 'Service',
        transform: row => <TdTextStatus state='highlight' title={row.service} />,
      },
      {
        label: 'Associated Carousel',
        transform: row => {
          if (row.associatedCarousel === 'error') {
            return <TdTextStatus state={'error'} title={'Error retrieving associated carousel information.'} />;
          }

          const url = getAssociatedCarouselUrl(row);

          if (!url) {
            return <TdTextStatus state={'highlight'} title={row.associatedCarousel?.name} />;
          }

          return <TdLink row={row} title={row.associatedCarousel?.name} url={url} target='_blank' />;
        },
      },

      {
        label: 'Policy',
        field: 'policy',
      },
      {
        label: 'Position',
        field: 'position',
      },
      ...(canEdit || canDelete
        ? [
            {
              label: 'Actions',
              colWidth: '6.25rem' as TSize,
              transform: (row, _col, index) => (
                <TableActions
                  row={row}
                  icons={[]}
                  deleteOption={index !== 0 && canDelete}
                  onClick={(_row, icon) => handleSourceServiceClick(icon, index)}
                >
                  <Popover
                    appendToBody={true}
                    maxWidth='26.25rem'
                    manualTrigger={true}
                    visible={sourceServicePopoverOpen[index]}
                    onClickOutside={() => setSourceServicePopoverOpen({})}
                  >
                    {canEdit && (
                      <Template label='trigger'>
                        <Icon space='small' icon='edit' onClick={() => handleSourceServiceClick('edit', index)} />
                      </Template>
                    )}
                    <Template label='popover'>
                      {sourceServicePopoverOpen[index] ? (
                        <SourceServiceForm
                          onCancel={() => setSourceServicePopoverOpen({})}
                          onSubmit={values => handleUpdateModel(values, index)}
                          carouselSourceService={row}
                          carouselSourceServiceList={rows}
                          index={index}
                          activeRegion={activeRegion}
                        />
                      ) : (
                        <div id='empty-source-service-form'></div>
                      )}
                    </Template>
                  </Popover>
                </TableActions>
              ),
            } as ITableCol<any>,
          ]
        : []),
    ],
    [
      activeRegion,
      canEdit,
      canDelete,
      getAssociatedCarouselUrl,
      handleSourceServiceClick,
      handleUpdateModel,
      rows,
      sourceServicePopoverOpen,
    ],
  );

  return (
    <>
      <Table
        cols={columns}
        rows={rows}
        loading={isLoadingRows}
        flushTop={true}
        id='sourcesServicesTable'
        emptyMsg={
          isErrorRows
            ? 'There was an error retrieving Source and Services rows. Please try again later.'
            : !rows?.length && !isLoadingRows
            ? 'No Sources and Services rows found.'
            : undefined
        }
      />

      <Cluster justify='space-between' align='center'>
        <div></div>
        {/* TODO: add CAN_EDIT here */}
        {/* {CAN_EDIT && ( */}
        <Popover
          manualTrigger={true}
          visible={sourceServicePopoverOpen.add}
          onClickOutside={() => setSourceServicePopoverOpen({})}
        >
          <Template label='trigger'>
            {canAddSourceService && (
              <Box marginTop='medium'>
                <Button
                  id='addSourcesAndServices'
                  type='primary'
                  onClick={() => setSourceServicePopoverOpen({add: true})}
                  permission={canEdit ? '' : 'disabled'}
                >
                  + Add
                </Button>
              </Box>
            )}
          </Template>
          <Template label='popover'>
            {sourceServicePopoverOpen['add'] ? (
              <SourceServiceForm
                onCancel={() => setSourceServicePopoverOpen({})}
                onSubmit={handleUpdateModel}
                carouselSourceServiceList={rows}
                activeRegion={activeRegion}
              />
            ) : (
              <div id='empty-source-service-form'></div>
            )}
          </Template>
        </Popover>
      </Cluster>
    </>
  );
};

export default SourceServiceTable;
